import Vue from 'vue'
import moment from 'moment'
import * as numeral from 'numeral'
import _isEquals from 'lodash/isEqual'

export const removeDiacritical = str => (str ? str.toLowerCase().trim().normalize('NFD')
  .replace(/[\u0300-\u036f]/g, '')
  .replace(/[đĐ]/g, 'd')
  .replace(/^-+|-+$/g, '') : '')

export const checkStatus = res => {
  if (res.status === 200) {
    if (res.data?.succeeded) {
      Vue.$toast.success(res.data.message)
      return true
    }
    return false
  }
  Vue.$toast.error(res.data.message)
  return null
}

export const compareObjects = (obj1, obj2) => _isEquals(obj1, obj2)

export const formatDate = date => (date ? moment(date).format('DD/MM/YYYY') : '')

export const formatDateTime = date => (date ? moment(date).format('DD/MM/YYYY HH:mm') : '')

export const formatDateRequest = date => (date ? moment(date).format('YYYY-MM-DD') : '')

export const formatDateRequestTime = date => (date ? moment(date).format('YYYY/MM/DD') : '')

export const formatDateTimeRequest = date => (date ? moment(date).format('YYYY-MM-DD HH:mm') : '')

export const formatDateTimeCheck = date => (date ? moment(date).format('DD/MM/YYYY HH:mm') : '')

export const formatDateCheckTime = date => (date ? moment(date).format('DD/MM/YYYY HH:mm A') : '')

export const formatNumberToCost = value => String(numeral(value).format()).replaceAll(',', '.')

export default {
  removeDiacritical,
  checkStatus,
  formatDate,
  formatDateRequest,
  formatDateTimeCheck,
  formatDateTimeRequest,
  formatDateRequestTime,
  formatNumberToCost,
  compareObjects,
}
